import React, { useEffect, useState } from "react";
import {
  FilterOption,
  filterOptionDescription,
} from "../../models/globals/enum/filterOption";
import { SearchParameters } from "../../models/searchParameters";
import { Collapse } from "reactstrap";

interface IFilterListProperties {
  fetchData: (searchParams: SearchParameters) => Promise<boolean>;
  clearResults: () => void;
  showNotify: (header: string, body: string) => void;
}

const FilterList: React.FC<IFilterListProperties> = (
  props: IFilterListProperties
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filterlist, setFilterlist] = useState<any[]>([]);
  const [isGastouder, setIsGastouder] = useState<boolean>(true);
  const [isGastouderBureau, setIsGastouderBureau] = useState<boolean>(true);
  const [isKinderdagverblijf, setIsKinderdagverblijf] = useState<boolean>(true);
  const [isBuitenschoolseOpvang, setIsBuitenschoolseOpvang] =
    useState<boolean>(true);
  const [hasReviews, setHasReviews] = useState<boolean>(false);
  const [isVoorschoolseEducatie, setIsVoorschoolseEducatie] =
    useState<boolean>(false);
  const [isOuderschapsParticipatie, setIsOuderschapsParticipatie] =
    useState<boolean>(false);

  // Search parameters
  const [searchName, setSearchName] = useState<string>("");
  const [searchStreetAndNumber, setSearchStreetAndNumber] = useState<string>("");
  const [searchPostalCode, setSearchPostalCode] = useState<string>("");
  const [searchCity, setSearchCity] = useState<string>("");
  const [searchPhoneNumber, setSearchPhoneNumber] = useState<string>("");
  const [searchLRKNumber, setSearchLRKNumber] = useState<string>("");
  const [searchKVKNumber, setSearchKVKNumber] = useState<string>("");
  const [searchEmail, setSearchEmail] = useState<string>("");
  const [searchWebsite, setSearchWebsite] = useState<string>("");

  useEffect(() => {
    // Load the filters from local storage
    let filterlistFromStorage = localStorage.getItem("filterlist");
    if (filterlistFromStorage !== null) {
      var filterData = JSON.parse(filterlistFromStorage);
      setFilterlist(filterData);

      setSearchName(filterData.find((x: any) => x.category === filterOptionDescription[FilterOption.Naam])?.value);
      setSearchStreetAndNumber(
        filterData.find((x: any) => x.category === filterOptionDescription[FilterOption.StraatHuisnummer])?.value
      );
      setSearchPostalCode(
        filterData.find((x: any) => x.category === filterOptionDescription[FilterOption.Postcode])?.value
      );
      setSearchCity(filterData.find((x: any) => x.category === filterOptionDescription[FilterOption.Stad])?.value);
      setSearchPhoneNumber(
        filterData.find((x: any) => x.category === filterOptionDescription[FilterOption.Telefoonnummer])?.value
      );
      setSearchLRKNumber(
        filterData.find((x: any) => x.category === filterOptionDescription[FilterOption.LRKNummer])?.value
      );
      setSearchKVKNumber(
        filterData.find((x: any) => x.category === filterOptionDescription[FilterOption.KVKNummer])?.value
      );
      setSearchEmail(filterData.find((x: any) => x.category === filterOptionDescription[FilterOption.Email])?.value);
      setSearchWebsite(
        filterData.find((x: any) => x.category === filterOptionDescription[FilterOption.Website])?.value
      );
    }

    let checkboxFilterlistFromStorage = localStorage.getItem("filterData");
    if (checkboxFilterlistFromStorage !== null) {
      let filterData = JSON.parse(checkboxFilterlistFromStorage);
      setIsGastouder(filterData.isGastouder);
      setIsGastouderBureau(filterData.isGastouderBureau);
      setIsKinderdagverblijf(filterData.isKinderdagverblijf);
      setIsBuitenschoolseOpvang(filterData.isBuitenschoolseOpvang);
      setIsVoorschoolseEducatie(filterData.isVoorschoolseEducatie);
      setIsOuderschapsParticipatie(filterData.isOuderschapsParticipatie);
      setHasReviews(filterData.hasReviews);
    }
  }, []);

  const addFilter = (value: string, filterType: string): void => {
    switch (filterType) {
      case filterOptionDescription[FilterOption.Naam]:
        setSearchName(value);
        break;
      case filterOptionDescription[FilterOption.StraatHuisnummer]:
        setSearchStreetAndNumber(value);
        break;
      case filterOptionDescription[FilterOption.Postcode]:
        setSearchPostalCode(value);
        break;
      case filterOptionDescription[FilterOption.Stad]:
        setSearchCity(value);
        break;
      case filterOptionDescription[FilterOption.LRKNummer]:
        setSearchLRKNumber(value);
        break;
      case filterOptionDescription[FilterOption.KVKNummer]:
        setSearchKVKNumber(value);
        break;
      case filterOptionDescription[FilterOption.Email]:
        setSearchEmail(value);
        break;
      case filterOptionDescription[FilterOption.Telefoonnummer]:
        setSearchPhoneNumber(value);
        break;
      case filterOptionDescription[FilterOption.Website]:
        setSearchWebsite(value);
        break;
    }

    let filterlistCopy = [...filterlist];
    let index = filterlistCopy.findIndex((x) => x.category === filterType);
    if (index === -1) {
      filterlistCopy.push({ category: filterType, value: value });
    } else {
      filterlistCopy[index].value = value;
    }
    setFilterlist(filterlistCopy);
  };

  const clearFilters = () => {
    props.clearResults();

    setIsGastouder(true);
    setIsGastouderBureau(true);
    setIsKinderdagverblijf(true);
    setIsBuitenschoolseOpvang(true);

    setIsVoorschoolseEducatie(false);
    setIsOuderschapsParticipatie(false);
    setHasReviews(false);

    setSearchName("");
    setSearchStreetAndNumber("");
    setSearchPostalCode("");
    setSearchCity("");
    setSearchPhoneNumber("");
    setSearchLRKNumber("");
    setSearchKVKNumber("");
    setSearchEmail("");
    setSearchWebsite("");

    setFilterlist([]);
    localStorage.removeItem("filterlist");
  };

  const showFiltersToggle = () => {
    setShowFilters(!showFilters);
    console.log("showFilters: " + showFilters);
  };

  const fetchSearchData = async () => {
    setIsLoading(true);
    props.clearResults();

    let searchParams: SearchParameters = new SearchParameters();
    searchParams.isGastouder = isGastouder;
    searchParams.isGastouderBureau = isGastouderBureau;
    searchParams.isKinderdagverblijf = isKinderdagverblijf;
    searchParams.isBuitenschoolseOpvang = isBuitenschoolseOpvang;
    searchParams.isOuderschapsParticipatie = isOuderschapsParticipatie;
    searchParams.isVoorschoolseEducatie = isVoorschoolseEducatie;
    searchParams.hasReviews = hasReviews;
    for (const [, value] of Object.entries(filterlist)) {
      switch (value.category) {
        case filterOptionDescription[FilterOption.Naam]:
          searchParams.name = value.value;
          break;
        case filterOptionDescription[FilterOption.StraatHuisnummer]:
          // Check if the value contains a number, if so split the string into street and house number
          if (/\d/.test(value.value)) {
            const splitIndex = value.value.lastIndexOf(" ");
            const street = value.value.slice(0, splitIndex);
            const houseNumber = value.value.slice(splitIndex + 1);
            searchParams.street = street;
            searchParams.houseNumber = houseNumber;
          } else {
            searchParams.street = value.value;
          }
          break;
        case filterOptionDescription[FilterOption.Postcode]:
          searchParams.postalCode = value.value;
          break;
        case filterOptionDescription[FilterOption.Stad]:
          searchParams.city = value.value;
          break;
        case filterOptionDescription[FilterOption.LRKNummer]:
          searchParams.lrkNumber = value.value;
          break;
        case filterOptionDescription[FilterOption.KVKNummer]:
          searchParams.kvkNumber = value.value;
          break;
        case filterOptionDescription[FilterOption.Email]:
          searchParams.email = value.value;
          break;
        case filterOptionDescription[FilterOption.Telefoonnummer]:
          searchParams.phoneNumber = value.value;
          break;
        case filterOptionDescription[FilterOption.Website]:
          searchParams.website = value.value;
          break;
      }
    }
    localStorage.setItem("filterlist", JSON.stringify(filterlist));
    await props.fetchData(searchParams); //TODO: DTM: Check if searchparams are filled correctly

    setIsLoading(false);
  };

  function toggleCheckboxFilter(value: string): void {
    const filterData = {
      isGastouder: isGastouder,
      isGastouderBureau: isGastouderBureau,
      isKinderdagverblijf: isKinderdagverblijf,
      isBuitenschoolseOpvang: isBuitenschoolseOpvang,
      isVoorschoolseEducatie: isVoorschoolseEducatie,
      isOuderschapsParticipatie: isOuderschapsParticipatie,
      hasReviews: hasReviews,
    };

    switch (value) {
      case "isGastouder":
        setIsGastouder(!isGastouder);
        filterData.isGastouder = !isGastouder;
        break;
      case "isGastouderBureau":
        setIsGastouderBureau(!isGastouderBureau);
        filterData.isGastouderBureau = !isGastouderBureau;
        break;
      case "isKinderdagverblijf":
        setIsKinderdagverblijf(!isKinderdagverblijf);
        filterData.isKinderdagverblijf = !isKinderdagverblijf;
        break;
      case "isBuitenschoolseOpvang":
        setIsBuitenschoolseOpvang(!isBuitenschoolseOpvang);
        filterData.isBuitenschoolseOpvang = !isBuitenschoolseOpvang;
        break;
      case "isVoorschoolseEducatie":
        setIsVoorschoolseEducatie(!isVoorschoolseEducatie);
        filterData.isVoorschoolseEducatie = !isVoorschoolseEducatie;
        break;
      case "isOuderschapsParticipatie":
        setIsOuderschapsParticipatie(!isOuderschapsParticipatie);
        filterData.isOuderschapsParticipatie = !isOuderschapsParticipatie;
        break;
      case "hasReviews":
        setHasReviews(!hasReviews);
        filterData.hasReviews = !hasReviews;
        break;
    }
    localStorage.setItem("filterData", JSON.stringify(filterData));
  }

  return (
    <div className="mt-4 mb-4">
      <h2>Zoeken</h2>

      <Collapse
        className="justify-content-end slide-in-left"
        isOpen={!showFilters}
        navbar
        expand="false"
      >
        <div className="card p-3" id="start-search">
          <div className="row">
            <div className="col-12">
              <span>
                <label className="col-form-label mt-2">Naam</label>
              </span>
              <input
                type="text"
                className="form-control"
                placeholder={`Opvang - De kleine beer`}
                aria-label="Search"
                aria-describedby="button-addon2"
                id="searchName"
                value={searchName}
                onChange={(e) => addFilter(e.target.value, filterOptionDescription[FilterOption.Naam])}
              />
            </div>

            <div className="col-6">
              <span>
                <label className="col-form-label mt-2">
                  Straat en huisnummer
                </label>
              </span>
              <input
                type="text"
                className="form-control"
                placeholder={`Beerstraat 1`}
                aria-label="Search"
                aria-describedby="button-addon2"
                id="searchStreetAndNumber"
                value={searchStreetAndNumber}
                onChange={(e) => addFilter(e.target.value, filterOptionDescription[FilterOption.StraatHuisnummer])}
              />

              <span>
                <label className="col-form-label mt-2">Postcode</label>
              </span>
              <input
                type="text"
                className="form-control"
                placeholder={`1111BE`}
                aria-label="Search"
                aria-describedby="button-addon2"
                id="searchPostalCode"
                value={searchPostalCode}
                onChange={(e) => addFilter(e.target.value, filterOptionDescription[FilterOption.Postcode])}
              />
            </div>

            <div className="col-6">
              <span>
                <label className="col-form-label mt-2">Stad</label>
              </span>
              <input
                type="text"
                className="form-control"
                placeholder={`Beerstad`}
                aria-label="Search"
                aria-describedby="button-addon2"
                id="searchCity"
                value={searchCity}
                onChange={(e) => addFilter(e.target.value, filterOptionDescription[FilterOption.Stad])}
              />

              <span>
                <label className="col-form-label mt-2">Telefoonnummer</label>
              </span>
              <input
                type="text"
                className="form-control"
                placeholder={`06-12345678`}
                aria-label="Search"
                aria-describedby="button-addon2"
                id="searchPhoneNumber"
                value={searchPhoneNumber}
                onChange={(e) => addFilter(e.target.value, filterOptionDescription[FilterOption.Telefoonnummer])}
              />
            </div>
          </div>

          <div className="form-group row mt-2">
            <div className="col-6">
              <span>
                <label className="col-form-label mt-2">LRK nummer</label>
              </span>
              <input
                type="number"
                className="form-control"
                placeholder={`123456789`}
                aria-label="Search"
                aria-describedby="button-addon2"
                id="searchLRKNumber"
                value={searchLRKNumber}
                onChange={(e) => addFilter(e.target.value, filterOptionDescription[FilterOption.LRKNummer])}
              />
            </div>

            <div className="col-6">
              <span>
                <label className="col-form-label mt-2">KVK nummer</label>
              </span>
              <input
                type="number"
                className="form-control"
                placeholder={`12345678`}
                aria-label="Search"
                aria-describedby="button-addon2"
                id="searchKVKNumber"
                value={searchKVKNumber}
                onChange={(e) => addFilter(e.target.value, filterOptionDescription[FilterOption.KVKNummer])}
              />
            </div>
          </div>

          <div className="form-group row mt-2">
            <div className="col-6">
              <span>
                <label className="col-form-label mt-2">Email</label>
              </span>
              <input
                type="text"
                className="form-control"
                placeholder={`teddy@opvangreview.nl`}
                aria-label="Search"
                aria-describedby="button-addon2"
                id="searchEmail"
                value={searchEmail}
                onChange={(e) => addFilter(e.target.value, filterOptionDescription[FilterOption.Email])}
              />
            </div>

            <div className="col-6">
              <span>
                <label className="col-form-label mt-2">Website</label>
              </span>
              <input
                type="text"
                className="form-control"
                placeholder={`www.opvangreview.nl`}
                aria-label="Search"
                aria-describedby="button-addon2"
                id="searchWebsite"
                value={searchWebsite}
                onChange={(e) => addFilter(e.target.value, filterOptionDescription[FilterOption.Website])}
              />
            </div>
          </div>

          <div className="form-group mt-2">
            <label className="col-form-label">Type</label> <br />
            <div className="row">
              <div className="col-6">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={isGastouder}
                    onChange={() => toggleCheckboxFilter("isGastouder")}
                    id="gastouderCheckbox"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="gastouderCheckbox"
                  >
                    Gastouder
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={isGastouderBureau}
                    onChange={() => toggleCheckboxFilter("isGastouderBureau")}
                    id="gastouderBureauCheckbox"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="gastouderBureauCheckbox"
                  >
                    Gastouderbureau
                  </label>
                </div>
              </div>

              <div className="col-6">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={isKinderdagverblijf}
                    onChange={() => toggleCheckboxFilter("isKinderdagverblijf")}
                    id="kinderdagverblijfCheckbox"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="kinderdagverblijfCheckbox"
                  >
                    Kinderdagverblijf
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={isBuitenschoolseOpvang}
                    onChange={() =>
                      toggleCheckboxFilter("isBuitenschoolseOpvang")
                    }
                    id="buitenschoolseOpvangCheckbox"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="buitenschoolseOpvangCheckbox"
                  >
                    Buitenschoolse Opvang
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group mt-2">
              <label className="col-form-label">Extra's</label> <br />
              <div className="row">
                <div className="col-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="isVoorschoolseEducatieCheckbox"
                      checked={isVoorschoolseEducatie}
                      onChange={() =>
                        toggleCheckboxFilter("isVoorschoolseEducatie")
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isVoorschoolseEducatieCheckbox"
                    >
                      Voorschoolse Educatie
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="isOuderschapsParticipatieCheckbox"
                      checked={isOuderschapsParticipatie}
                      onChange={() =>
                        toggleCheckboxFilter("isOuderschapsParticipatie")
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isOuderschapsParticipatieCheckbox"
                    >
                      Ouderschaps Participatie
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="showReviewsCheckbox"
                      checked={hasReviews}
                      onChange={() => toggleCheckboxFilter("hasReviews")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="showReviewsCheckbox"
                    >
                      Met reviews
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className="mt-3 mb-3">
            {!showFilters && (
              <button
                type="button"
                className="btn button-30 btn-outline-primary pr-3"
                onClick={showFiltersToggle}
                title="Verberg filters"
              >
                <i className="fa fa-chevron-left "></i>
              </button>
            )}

            <button
              className="btn button-30 btn-outline-success float-end"
              type="button"
              onClick={fetchSearchData}
              disabled={filterlist.length === 0}
            >
              {!isLoading && <i className="fa fa-search button-icon"></i>}
              {isLoading && (
                <i
                  className="spinner-border spinner-border-sm button-icon"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Zoeken
            </button>

            <button
              className="btn button-30 btn-outline-danger float-end pr-3"
              type="button"
              onClick={clearFilters}
              disabled={filterlist.length === 0}
            >
              <i className="fa fa-times button-icon"></i>
              Wissen
            </button>
          </div>
        </div>
      </Collapse>

      {showFilters && (
        <button
          type="button"
          className="btn button-30 btn-outline-primary"
          onClick={showFiltersToggle}
          title="Toon filters"
        >
          <i className="fa fa-chevron-right"></i>
        </button>
      )}
    </div>
  );
};

export default FilterList;
